<template>
<div>
    <!-- <h1>生成二维码</h1>
    <label for="text">请输入要转换为二维码的内容：</label>
    <input type="text" id="text" v-model="text" @keyup.enter="generateQRCode"/>
    <button @click="generateQRCode">生成</button> -->
    <div ref="qrcode" style="margin:0 auto;"></div>
    </div>
</template>

<script>

import QRCode from 'qrcodejs2'
export default {
  name: 'QRCodeGenerator',
  components: {
  },
  data() {
    return {
      text: '',
      qrcode: null,
    }
  },
  created() {
   
  },
  methods: {
   generateQRCode(url) {
      if (this.qrcode!=null) {
        this.qrcode.clear() // 清除原来的二维码
        this.$refs.qrcodeDiv.$el.innerHTML = '' 
      }
      let address = url+"?time="+new Date().getTime();
      this.qrcode = new QRCode(this.$refs.qrcode, {
        width: 256,
        height: 256,
        text: address
      })
    }
  },
}
</script>
<style lang="less" scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }
  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
</style>
<style>
.valid-error .ant-select-selection__placeholder {
  color: #f5222d;
}
.rightBox {
  padding: 30px;
  width: 370px;
  height: 430px;
  border-radius: 15px;
  overflow: hidden;
  padding-bottom: 10px;
}
.login_main {
  height: 100%;
}
.hlhs-p {
  font-size: 25px;
  color: #fff;
}
.hlhs-p2 {
  font-size: 16px;
  color: #fff;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #fff;
  font-size: 20px;
}
.ant-tabs-ink-bar-animated {
  display: none !important;
}
.logoinTitle {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  height: 100px;
  line-height: 100px;
  width: 100%;
  position: fixed;
  top: 0;
}
.footerTools {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}
.footerTools li {
  list-style: none;
  padding-left: 35px;
}
.login_footer {
  text-align: center;
}
.login_footer {
  position: fixed;
  bottom: 10px;
  width: 100%;
}
.login_footer div {
  margin-bottom: 5px;
}
</style>