var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: { model: _vm.model, rules: _vm.validatorRules }
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "roleName",
                    label: "部门角色名称"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入部门角色名称" },
                    model: {
                      value: _vm.model.roleName,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "roleName", $$v)
                      },
                      expression: "model.roleName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "roleCode",
                    label: "部门角色编码"
                  }
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: "请输入部门角色编码",
                      "read-only": _vm.roleCodeRead
                    },
                    model: {
                      value: _vm.model.roleCode,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "roleCode", $$v)
                      },
                      expression: "model.roleCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "description",
                    label: "描述"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入描述" },
                    model: {
                      value: _vm.model.description,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "description", $$v)
                      },
                      expression: "model.description"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }