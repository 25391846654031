<template>
  <a-modal
    :title="title"
    :width="800"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    cancelText="关闭">
    
    <a-spin :spinning="confirmLoading">
      <a-form-model  ref="form" :model="model" :rules="validatorRules">
      
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="roleName"
          label="部门角色名称">
          <a-input placeholder="请输入部门角色名称" v-model="model.roleName"/>
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="roleCode"
          label="部门角色编码">
          <a-input placeholder="请输入部门角色编码" v-model="model.roleCode"  :read-only="roleCodeRead"/>
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="description"
          label="描述">
          <a-input placeholder="请输入描述" v-model="model.description"/>
        </a-form-model-item>
		
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script>
  import { httpAction } from '@/api/manage'
  import {duplicateCheck } from '@/api/api'

  export default {
    name: "SysDepartRoleModal",
    data () {
      return {
        title:"操作",
        visible: false,
        model: {},
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        confirmLoading: false,
        validatorRules:{
          roleName:[
              { required: true, message: '请输入部门角色名称!' },
              { min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur' }
            ],
          roleCode: [
              { required: true, message: '请输入部门角色编码!'},
              { min: 0, max: 64, message: '长度不超过 64 个字符', trigger: 'blur' },
              { validator: this.validateRoleCode}
            ],
          description: [
              { min: 0, max: 126, message: '长度不超过 126 个字符', trigger: 'blur' }
            ]
        },
        url: {
          add: "/sys/sysDepartRole/add",
          edit: "/sys/sysDepartRole/edit",
        },
        roleCodeRead:false //编码时候可以编写
      }
    },
    created () {
    },
    methods: {
      add (departId) {
        this.edit({},departId);
      },
      edit (record,departId) {
        this.departId = departId;
        this.model = Object.assign({}, record);
        //update-begin---author:wangshuai ---date:20220104  for：[JTC-367]我的部门->部门角色 角色编码应该不可修改------------
        this.roleCodeRead = !!this.model.roleCode
        //update-end---author:wangshuai ---date:20220104  for：[JTC-367]我的部门->部门角色 角色编码应该不可修改------------
        this.visible = true;
      },
      close () {
        this.$emit('close');
        this.visible = false;
        this.$refs.form.resetFields()
      },
      handleOk () {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            this.model.departId = this.departId;
            httpAction(httpurl,this.model,method).then((res)=>{
              if(res.success){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
              that.close();
            })
          }else{
            return false;
          }
        })
      },
      handleCancel () {
        this.close()
      },
      validateRoleCode(rule, value, callback){
        if(/[\u4E00-\u9FA5]/g.test(value)){
          callback("部门角色编码不可输入汉字!");
        }else{
          var params = {
            tableName: "sys_depart_role",
            fieldName: "role_code",
            fieldVal: value,
            dataId: this.model.id,
          };
          duplicateCheck(params).then((res)=>{
            if(res.success){
              callback();
            }else{
              callback(res.message);
            }
          });
        }
      }
    }
  }
</script>

<style lang="less" scoped>

</style>