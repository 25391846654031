var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login_main" }, [
    _c("div", { staticClass: "logoinTitle" }, [_vm._v("题库111")]),
    _c(
      "div",
      {
        staticStyle: {
          height: "100%",
          display: "flex",
          "justify-content": "center",
          "align-items": "center"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "main rightBox",
            staticStyle: {
              width: "100%",
              "min-height": "350px",
              display: "flex",
              "justify-content": "space-around",
              "align-items": "center",
              background: "#0025f7"
            }
          },
          [
            _c("img", {
              attrs: { src: require("../../assets/data.png"), height: "400px" }
            }),
            _c(
              "div",
              { staticClass: "main rightBox" },
              [
                _c(
                  "a-form-model",
                  {
                    staticClass: "user-layout-login",
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleSubmit($event)
                      }
                    }
                  },
                  [
                    _c(
                      "a-tabs",
                      {
                        attrs: {
                          activeKey: _vm.customActiveKey,
                          tabBarStyle: {
                            textAlign: "center",
                            borderBottom: "unset"
                          }
                        },
                        on: { change: _vm.handleTabClick }
                      },
                      [
                        _c(
                          "a-tab-pane",
                          { key: "tab1", attrs: { tab: "欢迎登录" } },
                          [
                            _c("login-account", {
                              ref: "alogin",
                              on: {
                                validateFail: _vm.validateFail,
                                success: _vm.requestSuccess,
                                fail: _vm.requestFailed
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      [
                        _c(
                          "a-checkbox",
                          {
                            attrs: { "default-checked": "" },
                            on: { change: _vm.handleRememberMeChange }
                          },
                          [_vm._v("自动登录")]
                        ),
                        _c(
                          "router-link",
                          {
                            staticClass: "forge-password",
                            staticStyle: { float: "right" },
                            attrs: { to: { name: "alteration" } }
                          },
                          [_vm._v("\n              教师账号注册\n            ")]
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: { click: _vm.stuRrgister }
                          },
                          [_vm._v("学生账户注册")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      { staticStyle: { "margin-top": "24px" } },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "login-button",
                            attrs: {
                              size: "large",
                              type: "primary",
                              htmlType: "submit",
                              loading: _vm.loginBtn,
                              disabled: _vm.loginBtn
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.handleSubmit($event)
                              }
                            }
                          },
                          [_vm._v("确定\n            ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.requiredTwoStepCaptcha
                  ? _c("two-step-captcha", {
                      attrs: { visible: _vm.stepCaptchaVisible },
                      on: {
                        success: _vm.stepCaptchaSuccess,
                        cancel: _vm.stepCaptchaCancel
                      }
                    })
                  : _vm._e(),
                _c("login-select-tenant", {
                  ref: "loginSelect",
                  on: { success: _vm.loginSelectOk }
                }),
                _c("third-login", { ref: "thirdLogin" }),
                _c(
                  "a-modal",
                  {
                    attrs: {
                      visible: _vm.visible,
                      width: "310px",
                      title: "学生账户注册"
                    },
                    on: { cancel: _vm.handleClose },
                    scopedSlots: _vm._u([
                      {
                        key: "footer",
                        fn: function() {
                          return [
                            _c(
                              "a-button",
                              { key: "back", on: { click: _vm.handleClose } },
                              [_vm._v("关闭")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c("div", {
                      ref: "qrcode",
                      staticStyle: { margin: "0 auto" }
                    })
                  ]
                )
              ],
              1
            )
          ]
        )
      ]
    ),
    _c("div", { staticClass: "login_footer" }, [
      _c("div", [_vm._v("建议使用Chrome浏览器")]),
      _vm._m(0),
      _c(
        "div",
        [
          _vm._v("\n      Copyright\n      "),
          _c("a-icon", { attrs: { type: "copyright" } }),
          _c("span", [_vm._v("北京毅力澎湃技术有限公司 版权所有 保留一切权利")])
        ],
        1
      ),
      _c("div", [_vm._v("京ICP备15015852号-3")])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("ul", { staticClass: "footerTools" }, [
        _c("li", [_vm._v("隐私政策")]),
        _c("li", [_vm._v("用户声明")]),
        _c("li", [_vm._v("联系我们")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }